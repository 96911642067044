import React from 'react'
import { Box, Image, LoadingOverlay } from '@mantine/core'
import style from './car-grade-card.style.scss'
import { observer } from 'mobx-react-lite'
import { store } from '../../catalog.store'
import { useSetState } from '@mantine/hooks'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { CatalogCarModificationModel } from '../../models'
import { Link } from 'react-router-dom'

interface CarGradeCardProps {
    complectation: CatalogCarModificationModel['data']['complectations'][0]
}

export const CarGradeCard: React.FC<CarGradeCardProps> = observer(({ complectation, ...rest }) => {
    // const navigate = useNavigate()
    const [state, setState] = useSetState({ loader: true, error: false })

    // const handleClick = () => {
    //     if (store.params.onClickCarGrade) {
    //         store.params.onClickCarGrade(complectation)
    //     }
    // }

    return (
        <Box {...rest}>
            <div className={style.root}>
                <div className={style.content}>
                    <div className={style.image}>
                        <LoadingOverlay visible={state.loader} />
                        {!state.error ? (
                            <Box
                                component={LazyLoadImage}
                                height={'auto'}
                                src={complectation.image}
                                draggable={false}
                                afterLoad={async () => {
                                    setState({ loader: false })
                                }}
                                onError={() => setState({ loader: false, error: true })}
                            />
                        ) : (
                            <Image draggable={false} src={'/static/car-placeholder.png'} />
                        )}
                    </div>
                    <Link to={'/catalog/complectation/' + complectation.data.id} className={style.title}>
                        {store.data.model.brand_name} {store.data.model.model_name}
                    </Link>
                    <div className={style.type}>{complectation.data.complectation_name}</div>
                </div>
                <div className={style.price_block}>
                    <div className={style.price}>
                        <span className={style.price_title}>РРЦ</span>
                        <span className={style.price_value}>{complectation.rrp}</span>
                    </div>
                </div>
            </div>
        </Box>
    )
})
