export const format = (number: any, decimals = 0, dec_point = '.', separator = ' ') => {
    number = String(number).replace(/[^0-9+\-Ee.]/g, '')
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof separator === 'undefined' ? ',' : separator,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s: any = '',
        toFixedFix = function (n: any, prec: any) {
            var k = Math.pow(10, prec)
            return '' + (Math.round(n * k) / k).toFixed(prec)
        }
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    return s.join(dec)
}

export const randomObjectLength = (object) => {
    return Math.round(Math.random() * (Object.keys(object).length - 1))
}

export const randomArrayLength = (array) => {
    return Math.round(Math.random() * (array.length - 1))
}
