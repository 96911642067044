import React from 'react'
import { Box, LoadingOverlay, Image, clsx } from '@mantine/core'
import style from './car-card.style.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useImmer } from 'use-immer'
import { store } from '../../catalog.store'
import { observer } from 'mobx-react-lite'
import { core } from '../../catalog.core'
import { CatalogCarModel } from '../../models'
import { Link } from 'react-router-dom'

interface CarCardProps {
    car: CatalogCarModel
}

export const CarCard: React.FC<CarCardProps> = observer(({ car, ...rest }) => {
    const [state, setState] = useImmer({ loader: true, error: false })

    const link = React.useMemo(() => {
        // const old_data = JSON.parse(new URLSearchParams(window.location.search).get('data') || '{}')
        const data = {}

        data['filters'] = {}
        data['filters']['brand_id'] = [car.data.brand_id]
        data['filters']['model_id'] = [car.data.model_id]
        data['view_mode'] = 'modifications'
        // data['tab'] = old_data['tab']

        return `?data=${JSON.stringify(data)}`
    }, [])

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // if ((e.target as any).dataset['favorite']) {
        //     return
        // }

        if (e.button === 1) {
            return
        } else {
            e.preventDefault()
            e.stopPropagation()
            core.changeModification(car)
        }
    }

    return (
        <Box {...rest}>
            <div className={clsx(style.root, { [style.searching]: store.state.searching })}>
                <Box className={style.image}>
                    <LoadingOverlay visible={state.loader} />
                    <div>
                        {!state.error ? (
                            <Box
                                component={LazyLoadImage}
                                height={'auto'}
                                src={car.image}
                                draggable={false}
                                afterLoad={async () => {
                                    setState((draft) => {
                                        draft.loader = false
                                    })
                                }}
                                onError={() =>
                                    setState((draft) => {
                                        draft.loader = false
                                        draft.error = true
                                    })
                                }
                            />
                        ) : (
                            <Image draggable={false} src={'/static/car-placeholder.png'} />
                        )}
                    </div>
                </Box>
                <Link className={style.title} to={link} onClick={handleClick}>
                    {car.data.brand_name} {/* &mdash; */} {car.data.model_name}
                </Link>
                <div className={style.price_block}>
                    <div className={style.price}>
                        <span className={style.price_label}>РРЦ</span>
                        <span className={style.price_value}>{car.rrp}</span>
                    </div>
                </div>
            </div>
        </Box>
    )
})
