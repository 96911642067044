import { apiConfig } from '@/api'
import { number } from '@/shared'
import { BaseModel } from '@/shared/core/model'

type CatalogCarModelProps = {
    brand_id: number
    brand_name: string
    model_id: number
    model_name: string
    rrp: number
    image: string
}

export class CatalogCarModel extends BaseModel<CatalogCarModelProps, CatalogCarModel> {
    constructor(props: CatalogCarModelProps) {
        super(props, CatalogCarModel)
    }

    get image() {
        const image = this.data.image

        return image ? apiConfig.hosts.image_catalog + image : '/static/car-placeholder.png'
    }

    get rrp() {
        const price = number.format(this.data.rrp, 0, '.', ' ')
        const label_price = `от ${price} ₽`
        const label = 'по запросу'

        return this.data.rrp ? label_price : label
    }
}
