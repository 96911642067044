import { makeAutoObservable } from 'mobx'
import { TableColumn, TableMenu, TableMeta, TableParam, TableParams, TableRow } from './table.types'
import { R, tojs } from '@/shared'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import React from 'react'
import { core } from './table.core'
import { TableFilterModel } from './models'

type StoreState = State & {
    createModalOpened: boolean
    editModalOpened: boolean
}

export type StoreData = {
    page?: string
    title?: string
    states?: TableParam
    meta?: TableMeta
    columns?: TableColumn[]
    rows?: TableRow[]
    menu?: TableMenu
    search?: string
    filters?: TableFilterModel[]
    create?: string
    editRows?: TableRow[]
    selected?: TableRow[]
    actions?: {
        onClick: (rows: TableRow[], cor: typeof core) => void
        label: string
        icon?: React.ReactNode
        confirm?: boolean
    }[]
    limit?: {
        min: number
        max: number
    }
}

export type StoreParams = {
    search?: boolean | string
    // columnForDelete?: string
    // columnForEdit?: string
    // columnForLink?: string
    link?: string
    reset?: boolean
    selected?: boolean
    getLinkCell?: (row: TableRow) => string
    onClickDelete?: (rows: TableRow[]) => any[]
    onClickEdit?: (rows: TableRow[]) => ObjectType
}

const initialState: StoreState = {
    loading: false,
    loaded: false,
    init: false,
    error: null,
    createModalOpened: false,
    editModalOpened: false,
    creating: false,
}

const initialData: StoreData = {
    page: null,
    title: null,
    states: new Map([]),
    meta: null,
    rows: null,
    columns: null,
    menu: null,
    search: null,
    filters: [],
    create: null,
    editRows: [],
    selected: [],
    actions: [],
    limit: {
        min: 12,
        max: 42,
    },
}

const initialParams: StoreParams = {
    search: false,
    // columnForDelete: 'id',
    // columnForEdit: 'id',
    // columnForLink: null,
    link: null,
    reset: true,
    selected: false,
    getLinkCell: () => '',
    onClickDelete: () => [],
    onClickEdit: () => ({}),
}

class Store {
    state = initialState
    data = initialData
    params = initialParams

    constructor() {
        makeAutoObservable(this)
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Core                                    */
    /* -------------------------------------------------------------------------- */

    setState(states: Partial<StoreState>) {
        this.state = {
            ...this.state,
            ...states,
        }
    }

    setData(data: Partial<StoreData>) {
        this.data = {
            ...this.data,
            ...data,
        }
    }

    setParams(params: Partial<StoreParams>) {
        this.params = {
            ...this.params,
            ...params,
        }
    }

    reset() {
        this.setState(initialState)
        this.setData(initialData)
        this.setParams(initialParams)
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Data                                     */
    /* -------------------------------------------------------------------------- */

    get states() {
        const states = Array.from(this.data.states.values())

        return states
    }

    get currentState() {
        const state = tojs(this.states).find((state) => state.enabled)

        return state
    }

    get search() {
        return this.data.search
    }

    changeState(id: string) {
        const state = this.data.states.get(id)

        if (!state.enabled) {
            const states = this.states

            states.forEach((state) => (state.enabled = false))

            state.enabled = true
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Params                                   */
    /* -------------------------------------------------------------------------- */
    // getCellLink(idx: number) {
    //     const link = this.params.link
    //     const value = this.params.columnForLink ? this.data.rows[idx][this.params.columnForLink].value : ''

    //     return link + value
    // }

    /* -------------------------------------------------------------------------- */
    /*                                   Filters                                  */
    /* -------------------------------------------------------------------------- */

    getFilterValues() {
        const values = Array.from(tojs(this.data.filters).values())
            .flat()
            .filter((filter) => filter.data.value)
            .reduce((acc, curr) => {
                const value = tojs(curr.data.value)
                const key = curr.data.__searchParam?.[0] || curr.get.type()

                acc[key] = value.map((value) => (value?.id !== null && value?.id !== undefined ? value?.id : null))

                return acc
            }, {})

        return values
    }

    getFilterDependsOn(filter: TableFilterModel) {
        const depends = Array.from(this.data.filters.values())
            .flat()
            .filter((currentFilter) => {
                return filter.data.depends_on.includes(currentFilter.data.id)
            })

        return depends
    }

    getFilterDependsFor(filter: TableFilterModel) {
        const depends = Array.from(this.data.filters.values())
            .flat()
            .filter((currentFilter) => {
                return filter.data.depends_for.includes(currentFilter.data.id)
            })

        return depends
    }

    getFilterValuesByDependsOnForLoadValues(filter: TableFilterModel) {
        const depends = tojs(this.getFilterDependsOn(filter))

        const values = depends
            .filter((filter) => filter.data.value)
            .reduce((acc, curr) => {
                const value = tojs(curr.data.value)
                const key = curr.get.type()

                acc[key] = value.map((value) => (value?.id !== null && value?.id !== undefined ? value?.id : null))

                return acc
            }, {})

        return values
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Meta                                    */
    /* -------------------------------------------------------------------------- */
    get total() {
        return this.data.meta?.total || 0
    }

    get limit() {
        return this.data.meta?.per_page || store.data.limit.min
    }

    get pages() {
        return Math.ceil(this.total / this.limit) || 0
    }

    get page() {
        return this.data.meta?.current_page || 1
    }

    setLimit(limit: number) {
        this.data.meta.per_page = limit
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Search                                   */
    /* -------------------------------------------------------------------------- */
    getParamsForSearch() {
        const filters = this.getFilterValues()
        const state = this.currentState

        const values: TableParams = {
            'page[size]': this.limit,
            'page[number]': this.page,
            [this.params.search && typeof this.params.search === 'string' ? this.params.search : 'filters[search]']:
                this.search,
            ...filters,
        }

        if (state && state.value !== null) {
            values[state.param] = state.value
        }

        return R.filter((value: any) => value !== undefined && value !== null, values) as TableParams
    }

    /* -------------------------------------------------------------------------- */
    /*                                 Create/Edit                                */
    /* -------------------------------------------------------------------------- */
    getFieldsForCreate() {
        const columns = store.data.columns

        const fields =
            columns?.map((column) => {
                // if (column.model) {
                //     column.model.set.value(null)
                // }

                return { column: column, model: column.model }
            }) || []

        return fields
    }

    /**
     * Временно получаем модели из колонок.
     * Для мульти редактирования модели нужно хранить в row.cell.model
     */
    getFieldsForEdit(form: UseFormReturn<FieldValues, any>) {
        const editrow = tojs(store.data.editRows[0])

        if (editrow) {
            const fields = this.getFieldsForCreate()

            fields.forEach((field) => {
                if (field.column.type === 'string') {
                    field.model.set.string(editrow[field.column.id].value)
                    form.setValue(field.column.id, editrow[field.column.id].value)
                }

                if (field.column.type === 'select') {
                    /**
                     * Если нет списка значений, добавляем значения по умолчанию в values (чтобы не делать запрос всех значение),
                     * для корректной работы ui
                     */
                    if (!field.model.data.values.length) {
                        field.model.setData({ values: tojs(editrow[field.column.id].value) })
                        field.model.setState({ loaded: false })
                    }

                    if (!field.model.data.value.length) {
                        console.log(222, editrow[field.column.id]?.id)

                        field.model.set.value(editrow[field.column.id].value)
                    }

                    form.setValue(
                        field.column.id,
                        editrow[field.column.id]?.id || null
                        // field.model.getValueByValue(editrow[field.column.id].value)[0]?.id || null
                    )
                }

                if (field.column.type === 'multi-select') {
                    const ids = tojs(editrow[field.column.id].value).map((value) => value.id)
                    const values = tojs(editrow[field.column.id].value).map((value) => value.value)

                    if (!field.model.data.values.length) {
                        field.model.setData({ values: tojs(editrow[field.column.id].value) })
                        field.model.setState({ loaded: false })
                    }

                    if (!field.model.data.value.length) {
                        field.model.set.multiValue(values)
                    }

                    form.setValue(field.column.id, ids || [])
                }
            })

            return fields
        }

        return []
    }

    isSelected(row: TableRow) {
        const isExist = this.data.selected.find((selected) => JSON.stringify(selected) === JSON.stringify(row))

        return !!isExist
    }

    isSelectedOne() {
        return this.data.selected.length > 0
    }

    isSelectedAll() {
        const countRows = store.data.rows.length
        const countSelected = store.data.selected.length

        return countRows > 0 ? countRows === countSelected : false
    }
}

export const store = new Store()
